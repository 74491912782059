<template>
    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="250px" v-model="dateRangePickerVisible">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                outlined
                dense
                required
                v-model="dateRangeText"
                label="Range"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details="auto"
                @click:clear="clearDates"></v-text-field>
        </template>
        <v-card>
            <v-card-text>
                <v-date-picker
                    v-model="startDate"
                    :min="startDateMin"
                    :max="startDateMax"
                    first-day-of-week="1"
                    color="primary"
                    :full-width="$vuetify.breakpoint.smAndDown"></v-date-picker>
                <v-date-picker
                    v-model="endDate"
                    :min="endDateMin"
                    :max="endDateMax"
                    first-day-of-week="1"
                    color="primary"
                    :full-width="$vuetify.breakpoint.smAndDown"></v-date-picker>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="secondary" @click="hideDateRangePicker">Cancel</v-btn>
                <v-btn text color="primary" @click="selectDates">Select</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import { DateTime } from 'luxon'

export default {
    name: 'DateRangePicker',
    props: {
        dates: Array
    },
    data() {
        return {
            dateRangePickerVisible: false,
            startDate: null,
            startDateMin: null,
            startDateMax: null,
            endDate: null,
            endDateMin: null,
            endDateMax: null
        }
    },
    computed: {
        dateRangeText() {
            return `${this.dates[0] || ''} ~ ${this.dates[1] || ''}`
        }
    },
    mounted() {
        const now = DateTime.now().toISODate()
        this.startDateMax = now
        this.endDateMax = now
        this.startDate = this.dates[0] || DateTime.now().minus({ weeks: 1 }).toISODate()
        this.endDate = this.dates[1] || now
    },
    methods: {
        clearDates() {
            this.startDate = null
            this.endDate = null
        },
        selectDates() {
            this.$emit('input', [this.startDate, this.endDate])
            this.hideDateRangePicker()
        },
        hideDateRangePicker() {
            this.dateRangePickerVisible = false
        }
    },
    watch: {
        startDate(date) {
            const pickedMax = DateTime.fromISO(date).plus({ days: 364 })
            const today = DateTime.now()

            this.endDateMax = DateTime.min(pickedMax, today).toISODate()
            this.endDateMin = date
        },
        endDate(date) {
            const pickedMax = DateTime.fromISO(date).plus({ days: 364 })
            const today = DateTime.now()
            this.startDateMin = DateTime.fromISO(date).minus({ days: 364 }).toISODate()
            this.startDateMax = DateTime.min(pickedMax, today).toISODate()
        }
    }
}
</script>

<style lang="scss" scoped>
@media #{map-get($display-breakpoints, 'md-and-up')} {
    ::v-deep .v-picker {
        border-radius: 0;
        &:nth-of-type(1) {
            border-top-left-radius: 10px;
        }
        &:nth-of-type(2) {
            border-top-right-radius: 10px;
        }
    }
}
</style>
