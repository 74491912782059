<template>
    <div>
        <div v-intersect="intersectionObserver"></div>
        <div :class="isIntersecting ? '' : 'types-fixed'">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="4" class="d-flex align-center">
                        <v-autocomplete
                            v-if="!salesForce"
                            outlined
                            :items="$isError(resourcePools) ? [] : resourcePools"
                            :loading="fetching.resourcePools"
                            :disabled="fetching.resourcePools"
                            :error="$isError(resourcePools)"
                            :error-messages="getErrorMsg(resourcePools)"
                            v-model="selectedResourcePoolId"
                            item-text="long_id"
                            item-value="rpid"
                            dense
                            label="Resource pool"
                            placeholder="Type to search"
                            hide-details="auto"
                            @change="getResourceList('orgPools')"
                            auto-select-first>
                            <template v-slot:item="data">
                                <v-list-item-content :class="data.item.deleted && 'deleted'">{{ data.item.long_id }}</v-list-item-content>
                                <v-chip outlined color="grey lighten-1" class="ml-3" x-small>{{ userRole(data.item.is_rp_manager) }}</v-chip>
                            </template>
                        </v-autocomplete>
                        <template v-else>
                            <v-btn icon :disabled="!selectedRP.salesforce_uri" :href="selectedRP.salesforce_uri" target="_blank" class="mr-1">
                                <v-icon color="primary">mdi-salesforce</v-icon>
                            </v-btn>
                            <v-autocomplete
                                outlined
                                :items="$isError(resourcePools) ? [] : resourcePools"
                                :loading="fetching.resourcePools"
                                :disabled="fetching.resourcePools"
                                :error="$isError(resourcePools)"
                                :error-messages="getErrorMsg(resourcePools)"
                                v-model="selectedResourcePoolId"
                                item-text="salesforce_long_id"
                                item-value="rpid"
                                dense
                                label="Salesforce pool"
                                placeholder="Type to search"
                                hide-details="auto"
                                @change="getResourceList('orgPools')"
                                auto-select-first>
                                <template v-slot:item="data">
                                    <v-list-item-content :class="(data.item.deleted && 'deleted') || (data.item.not_in_salesforce && 'non-existent')">
                                        {{ data.item.salesforce_long_id }}
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </template>
                        <contacts-menu level="rp" :rpId="selectedResourcePoolId"></contacts-menu>
                        <div class="pl-6" :class="{ hidden: !showOrgPools }">
                            <v-icon>keyboard_arrow_right</v-icon>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4" class="d-flex align-center">
                        <v-autocomplete
                            v-if="showOrgPools"
                            outlined
                            :items="$isError(orgPools) ? [] : orgPools"
                            :loading="fetching.orgPools"
                            :disabled="fetching.orgPools"
                            :error="$isError(orgPools)"
                            :error-messages="getErrorMsg(orgPools)"
                            v-model="selectedOrgId"
                            item-text="long_id"
                            item-value="oid"
                            dense
                            label="Organization"
                            placeholder="Type to search"
                            hide-details="auto"
                            @change="getResourceList('spacePools')"
                            clearable
                            auto-select-first>
                            <template v-slot:item="data">
                                <v-list-item-content :class="data.item.deleted && 'deleted'">
                                    {{ data.item.long_id }}
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <contacts-menu v-if="showOrgPools" level="org" :rpId="selectedResourcePoolId" :orgId="selectedOrgId"></contacts-menu>
                        <div class="pl-6" :class="{ hidden: !showSpacePools }">
                            <v-icon>keyboard_arrow_right</v-icon>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="4" class="d-flex align-center justify-end">
                        <v-autocomplete
                            v-if="showSpacePools"
                            outlined
                            :items="$isError(spacePools) ? [] : spacePools"
                            :loading="fetching.spacePools"
                            :disabled="fetching.spacePools"
                            :error="$isError(spacePools)"
                            :error-messages="getErrorMsg(spacePools)"
                            v-model="selectedSpaceId"
                            item-text="long_id"
                            item-value="sid"
                            dense
                            label="Space"
                            placeholder="Type to search"
                            hide-details="auto"
                            clearable
                            auto-select-first>
                            <template v-slot:item="data">
                                <v-list-item-content :class="data.item.deleted && 'deleted'">
                                    {{ data.item.long_id }}
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <contacts-menu v-if="showSpacePools" level="space" :rpId="selectedResourcePoolId" :spaceId="selectedSpaceId"></contacts-menu>
                        <div class="pl-6" v-if="isAccountManager || isSysadmin">
                            <v-menu :close-on-content-click="true" offset-y bottom left>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="secondary" icon dark class="ml-1">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense nav>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <div class="d-flex align-center">
                                                <v-checkbox v-model="salesForce" label="Use Salesforce names"></v-checkbox>
                                            </div>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row>
                <v-col>
                    <v-card class="mt-3 overflow-hidden">
                        <v-tabs ref="tabs" color="primary" v-model="tab">
                            <v-tab v-for="(tab, index) in visibleTabs" :key="`tab-${index}`">
                                <v-icon small left>{{ tab.icon }}</v-icon>
                                {{ tab.label }}
                            </v-tab>
                        </v-tabs>
                    </v-card>

                    <v-tabs-items class="mt-7 overflow-visible" v-model="tab">
                        <v-container v-if="showDatePicker">
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                                    <v-select
                                        outlined
                                        :items="dateRangeOptions"
                                        dense
                                        :menu-props="{ offsetY: true }"
                                        label="Date range"
                                        v-model="selectedDateRangeOption"
                                        hide-details="auto"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-if="showDatePicker && selectedDateRangeOption === 'Custom range'">
                                    <date-range-picker v-model="dates" :dates="dates"></date-range-picker>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-tab-item v-for="(tab, index) in visibleTabs" :key="`tab-${index}`" class="overflow-visible">
                            <component
                                :is="tab.component"
                                v-bind="{
                                    rpid: selectedResourcePoolId,
                                    oid: selectedOrgId,
                                    sid: selectedSpaceId,
                                    dates: dates,
                                    rpManager: isRPManager,
                                    selectedRP: selectedRP,
                                    managedResourcePools: managedResourcePools
                                }"></component>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapState } from 'vuex'
import { find } from 'lodash'
import DateRangePicker from '@/components/DateRangePicker.vue'

const MonitoringOverview = () => import('../components/MonitoringOverview.vue')
const PlanUtilization = () => import('../components/PlanUtilization.vue')
const CreditUtilization = () => import('../components/CreditUtilization.vue')
const ResourcePoolInventory = () => import('../components/ResourcePoolInventory.vue')
const ContactsMenu = () => import('../components/ContactsMenu.vue')

export default {
    name: 'MonitoringDashboard',
    components: { MonitoringOverview, PlanUtilization, CreditUtilization, DateRangePicker, ContactsMenu, ResourcePoolInventory },
    data() {
        return {
            resourcePools: [],
            orgPools: [],
            spacePools: [],
            fetching: {
                resourcePools: false,
                orgPools: false,
                spacePools: false
            },
            selectedResourcePoolId: null,
            selectedOrgId: null,
            selectedSpaceId: null,
            dateRangeOptions: ['Current month', 'Previous month', 'Custom range'],
            selectedDateRangeOption: 'Current month',
            dates: [],
            dateMax: null,
            tab: null,
            tabs: [
                { icon: 'line_axis', label: 'Overview', component: 'MonitoringOverview' },
                { icon: 'data_usage', label: 'Plan utilization', component: 'PlanUtilization', visible: 'isRPManager' },
                { icon: 'savings', label: 'Credit utilization', component: 'CreditUtilization' },
                { icon: 'checklist', label: 'Inventory', component: 'ResourcePoolInventory', visible: 'isRPManager' }
            ],
            salesForce: false,
            errorMessage: 'Failed to fetch resource list!',
            isIntersecting: false,
            intersectionObserver: {
                handler: this.onIntersect,
                options: {
                    // 48px in the CSS too!
                    rootMargin: '-48px 0px 0px 0px',
                    threshold: 1.0
                }
            }
        }
    },
    async created() {
        await this.getResourceList()
        this.selectedResourcePoolId = Number(this.$route.query.rpid) || this.selectedResourcePoolId || this.resourcePools[0].rpid

        this.dateMax = DateTime.now().toISODate()
        // To get orgPools for auto selected first RP
        await this.getResourceList('orgPools')
    },
    methods: {
        async getResourceList(target = 'resourcePools') {
            if (target === 'orgPools') this.selectedOrgId = null
            if (target === 'spacePools') this.selectedSpaceId = null
            try {
                this.fetching[target] = true
                const { data } = await this.$axios.get(this.rpTreeURL)
                this[target] = data
            } catch (error) {
                this[target] = error
            } finally {
                this.fetching[target] = false
            }
        },
        getErrorMsg(target) {
            return this.$isError(target) ? this.errorMessage : ''
        },
        userRole(manager) {
            return manager ? 'MANAGER' : 'MEMBER'
        },
        onIntersect(entries, observer) {
            this.isIntersecting = entries[0].isIntersecting
        }
    },
    computed: {
        ...mapState('userStore', ['isAccountManager']),
        ...mapState(['userInfo']),
        rpTreeURL() {
            if (!this.selectedResourcePoolId) return `/resource/rp_tree${this.salesForce ? '?salesforce=true' : ''}`
            let url = `/resource/rp_tree/rpid/${this.selectedResourcePoolId}`
            if (this.selectedOrgId) url += `/oid/${this.selectedOrgId}`
            return url
        },
        showOrgPools() {
            return this.selectedResourcePoolId && this.tab !== 0
        },
        showSpacePools() {
            return this.selectedOrgId && ![0, 3].includes(this.tab)
        },
        showDatePicker() {
            return ![0, 3].includes(this.tab)
        },
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
        isSysadmin() {
            return this.userInfo && this.userInfo.is_sysadmin
        },
        visibleTabs() {
            return this.tabs.filter(tab => {
                if (!tab.visible) return true
                return this[tab.visible]
            })
        },
        isRPManager() {
            return this.selectedRP?.is_rp_manager
        },
        selectedRP() {
            return find(this.resourcePools, { rpid: this.selectedResourcePoolId })
        },
        managedResourcePools() {
            return this.resourcePools.filter(rp => rp.is_rp_manager)
        }
    },
    watch: {
        selectedDateRangeOption: {
            handler(dateOption) {
                const now = DateTime.now()
                let monthStart = this.dates[0]
                let monthEnd = this.dates[1]

                if (dateOption === 'Current month') {
                    monthStart = now.startOf('month').toISODate()
                    monthEnd = now.endOf('month').toISODate()
                }

                if (dateOption === 'Previous month') {
                    const prevMonth = now.minus({ months: 1 })
                    monthStart = prevMonth.startOf('month').toISODate()
                    monthEnd = prevMonth.endOf('month').toISODate()
                }

                this.dates = [monthStart, monthEnd]
            },
            immediate: true
        },
        salesForce: {
            async handler() {
                const rpid = this.selectedResourcePoolId
                const oid = this.selectedOrgId
                const sid = this.selectedSpaceId

                this.resourcePools = []

                this.selectedResourcePoolId = null
                this.selectedOrgId = null
                this.selectedSpaceId = null
                await this.getResourceList()
                this.selectedResourcePoolId = rpid
                this.selectedOrgId = oid
                this.selectedSpaceId = sid
            }
        },
        selectedResourcePoolId() {
            this.selectedOrgId = null
            this.selectedSpaceId = null
        },
        selectedOrgId() {
            this.selectedSpaceId = null
        }
    }
}
</script>

<style lang="scss" scoped>
.types-fixed {
    position: fixed;
    margin: 0;
    z-index: 1;
    top: 48px;
    width: 100%;
    left: 0;
    background-color: white;
}
.overflow-visible {
    overflow: visible;
}

.hidden {
    visibility: hidden;
}

.deleted {
    text-decoration: line-through;
    opacity: 0.5;
}

.non-existent {
    opacity: 0.5;
}
</style>
